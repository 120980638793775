/** @jsxImportSource @emotion/react */
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const PieceOfLineOne = () => {
  return (
    <>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselImage3.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselImage1.png`}
          imgStyle={{ borderRadius: "120px", width: "100%" }}
        />
      </Box>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselShape1.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselImage2.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselImage4.png`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
      <Box sx={{}} className="embla__slide">
        <StaticImage
          alt=""
          src={`../../images/carouselShape2.svg`}
          imgStyle={{ borderRadius: "120px" }}
        />
      </Box>
    </>
  );
};

const PhotoLineOne = () => {
  return (
    <Box
      sx={{
        marginBottom: "40px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          position: "relative",
          width: { md: "200%", xs: "600%" },
          aspectRatio: "2700 / 180",
          maxWidth: "3712px",
          margin: "auto",
          alignItems: "center",
        }}
        className="photoLineOne"
      >
        <PieceOfLineOne />
        <PieceOfLineOne />
      </Stack>
    </Box>
  );
};

export default PhotoLineOne;
