import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";
import CardsSection from "./CardsSection";
import Title from "./Title";

const OnlyTheBest = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ position: "relative" }}>
      <MabiParallax
        id="onlytTheBestParallax"
        duration={2}
        tweens={
          isOverMd
            ? [
                {
                  selector: ".deco1",
                  from: {
                    x: 100,
                    y: -50,
                    rotate: "30deg",
                  },
                  to: {
                    x: 0,
                    y: 50,
                    rotate: "0deg",
                  },
                },
                {
                  selector: ".deco2",
                  from: {
                    x: 200,
                  },
                  to: {
                    x: 0,
                  },
                },
                {
                  selector: ".deco3",
                  from: {
                    x: 50,
                    y: 100,
                    rotate: "-200deg",
                  },
                  to: {
                    x: 0,
                    y: 0,
                    rotate: "0deg",
                  },
                },
              ]
            : []
        }
      >
        <Title />
        <CardsSection />
        <Box
          sx={{
            position: "absolute",
            top: { md: 0, xs: "8%" },
            right: "10%",
            transform: { md: "scale(1)", xs: "scale(0.7)" },
          }}
          className="deco1"
        >
          <StaticImage alt="" src="../../images/onlyTheBestDeco1.svg" />
        </Box>
        {isOverMd && (
          <Box
            sx={{
              position: "absolute",
              left: "2%",
              top: "50%",
            }}
            className="deco2"
          >
            <StaticImage alt="" src="../../images/onlyTheBestDeco2.svg" />
          </Box>
        )}
      </MabiParallax>
    </Box>
  );
};

export default OnlyTheBest;
