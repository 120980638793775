import { Button, List, ListItem, Stack } from "@mui/material";
import { l_careers } from "../../locales";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import CareerTypography from "../../theme/CareerTypography";

interface OpenPositionListItemProps {
  jobTitle: string;
  yearsOfRequiredExperience: number;
  jobDescription: string;
  link: string;
}

const OpenPositionListItem: React.FC<OpenPositionListItemProps> = ({
  jobTitle,
  yearsOfRequiredExperience,
  jobDescription,
  link,
}) => {
  return (
    <ListItem
      sx={{
        backgroundColor: "white",
        padding: "32px",
        borderRadius: "30px",
        marginBottom: "20px",
        alignItems: { md: "center", xs: "baseline" },
        justifyContent: "space-between",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      <Stack
        sx={{
          flexDirection: "column",
          width: { md: "30%", xs: "100%" },
          gap: "8px",
        }}
      >
        <CareerTypography
          sx={{
            fontSize: { md: "28px", xs: "24px" },
            lineHeight: "32px",
            fontWeight: { md: 600, xs: 700 },
          }}
        >
          {jobTitle}
        </CareerTypography>
        <CareerTypography
          variant="body2"
          sx={{
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: { md: 900, xs: 700 },
            color: "black",
          }}
        >
          {yearsOfRequiredExperience > 0
            ? `${yearsOfRequiredExperience}+ ${l_careers(
                "openPositions.yearsOfExp"
              )}`
            : l_careers("openPositions.noExp")}
        </CareerTypography>
      </Stack>

      <CareerTypography
        sx={{
          width: { md: "50%", xs: "100%" },
          padding: { md: "0 24px", xs: "24px 0" },
          marginRight: { md: "5%", xs: 0 },
        }}
      >
        {jobDescription}
      </CareerTypography>

      <Button
        variant="outlined"
        sx={{
          borderColor: "#E85322",
          backgroundColor: "transparent",
          borderRadius: "56px",
          fontSize: "16px",
          lineHeight: "16px",
          fontWeight: 700,
          padding: { md: "24px 68px", xs: "20px 24px" },
          borderWidth: "2px",
          width: { xs: "90%", md: "20%" },
          maxWidth: "300px",
          alignSelf: "center",

          ":hover": {
            borderColor: "inherit",
            backgroundColor: "#E8532212",
            borderWidth: "2px",
          },
        }}
        startIcon={<ButtonDotIcon />}
        href={`./${link}/`}
      >
        {l_careers("openPositions.buttonText")}
      </Button>
    </ListItem>
  );
};

const OpenPositionsList = () => {
  const openPositons = [
    {
      jobTitle: l_careers("openPositions.jobTitle1"),
      yearsOfRequiredExperience: 2,
      jobDescription: l_careers("openPositions.jobDescr1"),
      link: "mobile-developer",
    },
    {
      jobTitle: l_careers("openPositions.jobTitle2"),
      yearsOfRequiredExperience: 2,
      jobDescription: l_careers("openPositions.jobDescr2"),
      link: "business-developer",
    },
    {
      jobTitle: l_careers("openPositions.jobTitle3"),
      yearsOfRequiredExperience: 2,
      jobDescription: l_careers("openPositions.jobDescr3"),
      link: "digital-product-designer",
    },
    {
      jobTitle: l_careers("openPositions.jobTitle0"),
      yearsOfRequiredExperience: 0,
      jobDescription: l_careers("openPositions.jobDescr0"),
      link: "open-application",
    },
  ];

  return (
    <List sx={{}}>
      {openPositons.map((job, ind) => {
        return (
          <OpenPositionListItem
            key={job.jobTitle}
            jobTitle={job.jobTitle}
            jobDescription={job.jobDescription}
            yearsOfRequiredExperience={job.yearsOfRequiredExperience}
            link={job.link ?? ""}
          />
        );
      })}
    </List>
  );
};

export default OpenPositionsList;
