import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_careers } from "../../locales";
import CareerTypography from "../../theme/CareerTypography";

interface CardProps {
  title: string;
  description: string;
  decoReferenceFirst?: boolean;
  decoReferenceSecond?: boolean;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  decoReferenceFirst,
  decoReferenceSecond,
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item xs={12} sm={12} md={4} sx={{ position: "relative" }}>
      <Box sx={{ marginBottom: { xs: "40px" } }}>
        <CareerTypography variant="title2" sx={{ marginBottom: "8px" }}>
          {title}
        </CareerTypography>
        <CareerTypography variant="body2">{description}</CareerTypography>
      </Box>
      {decoReferenceFirst && !isOverMd && (
        <Box
          sx={{
            position: "absolute",
            left: "5%",
            bottom: 0,
          }}
        >
          <StaticImage alt="" src="../../images/onlyTheBestDeco2.svg" />
        </Box>
      )}
      {decoReferenceSecond && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: "2%",
            transform: { md: "scale(1)", xs: "scale(0.8) translateY(-100%)" },
          }}
          className="deco3"
        >
          <StaticImage alt="" src="../../images/onlyTheBestDeco3.svg" />
        </Box>
      )}
    </Grid>
  );
};

const CardsSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const onlyTheBestCards = [
    {
      title: l_careers("onlyTheBest.card1Title"),
      description: l_careers("onlyTheBest.card1Description"),
    },
    {
      title: l_careers("onlyTheBest.card2Title"),
      description: l_careers("onlyTheBest.card2Description"),
    },
    {
      title: l_careers("onlyTheBest.card3Title"),
      description: l_careers("onlyTheBest.card3Description"),
    },
    {
      title: l_careers("onlyTheBest.card4Title"),
      description: l_careers("onlyTheBest.card4Description"),
    },
    {
      title: l_careers("onlyTheBest.card5Title"),
      description: l_careers("onlyTheBest.card5Description"),
    },
    {
      title: l_careers("onlyTheBest.card6Title"),
      description: l_careers("onlyTheBest.card6Description"),
    },
    {
      title: l_careers("onlyTheBest.card7Title"),
      description: l_careers("onlyTheBest.card7Description"),
    },
    {
      title: l_careers("onlyTheBest.card8Title"),
      description: l_careers("onlyTheBest.card8Description"),
    },
    {
      title: l_careers("onlyTheBest.card9Title"),
      description: l_careers("onlyTheBest.card9Description"),
    },
  ];

  return (
    <Stack sx={{ width: "90%", margin: "auto", maxWidth: "1800px" }}>
      <Grid container columnSpacing={3} rowSpacing={isOverMd ? 6 : 2}>
        {onlyTheBestCards.map((card, ind) => {
          return (
            <Card
              key={card.title}
              title={card.title}
              description={card.description}
              decoReferenceFirst={ind === 3 ? true : false}
              decoReferenceSecond={ind === 8 ? true : false}
            />
          );
        })}
      </Grid>
    </Stack>
  );
};

export default CardsSection;
