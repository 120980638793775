/** @jsxImportSource @emotion/react */
import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import { PageProps } from "../../components/utils/PageProps";
import BeLofters from "./components/BeLofters/BeLofters";
import DreamersAndInnovators from "./components/DreamersAndInnovators/DreamersAndInnovators";
import Header from "./components/Header/Header";
import OnlyTheBest from "./components/OnlyTheBest/OnlyTheBest";
import OpenPositions from "./components/OpenPositions/OpenPositions";
import OpinionsCards from "./components/OpinionsCards/OpinionsCards";
import PhotosCarousel from "./components/PhotosCarousel/PhotosCarousel";
import ScrollingPhotos from "./components/ScrollingPhotos/ScrollingPhotos";
import VideoSection from "./components/VideoSection/VideoSection";
import { l_careers } from "./locales";

const Careers = (props: PageProps) => {
  return (
    <>
      <SEO title="Careers" description={l_careers("seo.description")} />

      <Header />
      <BeLofters />
      <PhotosCarousel />
      <OpinionsCards />
      <DreamersAndInnovators />
      <ScrollingPhotos />
      <OnlyTheBest />
      <VideoSection />
      <OpenPositions />
      <Footer />
    </>
  );
};

export default Careers;
